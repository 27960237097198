import React from "react";
import ReactDOM from "react-dom/client";
import { AppEntry } from "./AppEntry";

// Create root element
const rootElement = document.createElement("div");
rootElement.id = "app";
document.body.appendChild(rootElement);

ReactDOM.createRoot(document.getElementById("app")).render(<AppEntry />);

// Register service worker
if ("serviceWorker" in navigator) {
    // Make sure the service worker isnt already registered
    navigator.serviceWorker.register("/service-worker.js");
} else {
    console.warn("[VP] Service worker not supported");
}
